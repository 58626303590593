.box {
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  background-color: #c6d6ff;
  border-radius: 10px;

  @media (max-width: 767px) {
    margin-right: 10px;
    width: 160px;
  }
}
.box > p {
  margin-top: 2px;
  font-size: 13px;
}
.box > img {
  width: 45px;
}

.animate_wrapper {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  mask: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 0, 0) 11%,
    rgb(255, 0, 0) 91%,
    rgba(255, 255, 255, 0) 100%
  );
}

.animate_wrapper_bottom {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  mask: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 0, 0) 11%,
    rgb(255, 0, 0) 91%,
    rgba(255, 255, 255, 0) 100%
  );
}

.scroller {
  display: flex;
  animation: scroll 20s linear infinite;
  margin-left: -100%;
  @media (max-width: 767px) {
    margin-left: -320%;
  }
}
.scroller_bottom {
  display: flex;
  animation: scroll_right 20s linear infinite;
}

/* #C6D6FF */
@keyframes scroll_right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%);
  }
}
